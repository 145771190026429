import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const FeatureGrid = ({ gridItems }) => (
  <>
    {/* {gridItems.map((item, index) => {
      if (index % 2) {
        return (
          <section className="section">
            <div className="container">
              <div className="columns">
                <div className="column is-12">
                  <div className="columns">
                    <div key={item.text} className="column is-6" style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                      <p>{item.text}</p>
                    </div>
                    <div key={item.text} className="column is-6">
                      <div className="has-text-centered">
                        <div
                          style={{
                            width: "100%",
                            display: "inline-block",
                          }}
                        >
                          <PreviewCompatibleImage imageInfo={item} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else {
        return (
          <section className="section" style={{ background: "#3273dc14" }}>
            <div className="container">
              <div className="columns">
                <div className="column is-12">
                  <div className="columns">
                    <div key={item.text} className="column is-6">
                      <div className="has-text-centered">
                        <div
                          style={{
                            width: "100%",
                            display: "inline-block",
                          }}
                        >
                          <PreviewCompatibleImage imageInfo={item} />
                        </div>
                      </div>
                    </div>
                    <div key={item.text} className="column is-6" style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }
    })} */}

    <section className="section">
      <div className="container">
        <div className="columns">
          <div
            className="column is-6 group-images"
           
          >
            {gridItems.map((item, index) => (
              <div className="column is-6">
                <PreviewCompatibleImage imageInfo={item} />
              </div>
            ))}
          </div>
          <div
            className="column is-6 group-images"
           
          >
            <div className="column is-6" style={{ alignSelf: "center" }}>
              <section class="hero" style={{ backgroundColor: "#16588E", color: "white", border:"10px double" }}>
                <div class="hero-body">
                  <p class="title has-text-centered" style={{color:"white"}}>Safety</p>
                </div>
              </section>
            </div>
            <div className="column is-6" style={{ alignSelf: "center" }}>
              <section class="hero" style={{ backgroundColor: "#16588E", color: "white",border:"10px double" }}>
                <div class="hero-body">
                  <p class="title has-text-centered" style={{color:"white"}}>Trust</p>
                </div>
              </section>
            </div>
            <div className="column is-6" style={{ alignSelf: "center" }}>
              <section class="hero" style={{ backgroundColor: "#16588E", color: "white",border:"10px double" }}>
                <div class="hero-body">
                  <p class="title has-text-centered" style={{color:"white"}}>Reliability</p>
                </div>
              </section>
            </div>
            <div className="column is-6" style={{ alignSelf: "center" }}>
              <section class="hero" style={{ backgroundColor: "#16588E", color: "white", border:"10px double" }}>
                <div class="hero-body">
                  <p class="title has-text-centered" style={{color:"white"}}>Comfort</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
