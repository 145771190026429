import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import Quote from "../pages/contact/quote";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import {
  GiAcousticMegaphone,
  GiPartyPopper,
  GiRugbyConversion,
  GiSteeringWheel,
} from "react-icons/gi";
import { MdLocalAirport, MdAirportShuttle } from "react-icons/md";
import {
  FaFootballBall,
  FaShuttleVan,
  FaTicketAlt,
  FaUserTie,
} from "react-icons/fa";
import { RiSteering2Fill } from "react-icons/ri";
import { HiOutlineCurrencyPound } from "react-icons/hi";
{
  /* <PreviewCompatibleImage
imageInfo={{
  ...mainpitch,
  style: { boxShadow: "10px 5px 5px #E7222E" },
}}
/> */
}
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  headline,
}) => {
  const [showQuoteForm, setShowQuoteForm] = useState(false);

  function toggleShowQuoteForm(set) {
    if (set !== null) {
      setShowQuoteForm(set);
    } else {
      setShowQuoteForm(!showQuoteForm);
    }
  }
  return (
    <div>
      <h1
        className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen main-heading"
        style={{ marginTop: "100px" }}
      >
        {title}
      </h1>
      {/* <Quote /> */}
      <h3
        className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen main-heading"
        style={{ marginTop: "200px" }}
      >
        {subheading}
      </h3>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
          backgroundColor: "rgba(22, 88,142,0.5)",
          backgroundBlendMode: "multiply",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            lineHeight: "1",
            justifyContent: "center",
            alignItems: "left",
            flexDirection: "column",
          }}
          className="container"
        ></div>
      </div>
      <section className="hero is-info">
        <div className="hero-body">
          <p className="title">{headline}</p>
          {/* {!showQuoteForm && (
            <div className="has-text-centered">
              <button
                className="button is-link has-text-centered"
                onClick={() => toggleShowQuoteForm(true)}
              >
                Get a quote
              </button>
            </div>
          )}
          {showQuoteForm && <Quote toggleShowQuoteForm={toggleShowQuoteForm} />} */}
          {/* <p class="subtitle">Info subtitle</p> */}
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="columns">
              <div className="column is-3 has-text-centered">
                <MdLocalAirport
                  size={50}
                  color="#16588E"
                  fill="none"
                  strokeWidth={0.5}
                />
                <h1 className="is-size-6 has-text-weight-bold">
                  Airport Transfers
                </h1>
                <p className="p-padding">
                  Whether you are flying out or flying in, travelling with
                  friends or family, embarking on a business trip or just a
                  holiday. Have peace of mind and let us transport you to and
                  from any airport in the UK.
                </p>
              </div>
              <div className="column is-3 has-text-centered">
                <FaTicketAlt
                  size={50}
                  color="#16588E"
                  fill="none"
                  strokeWidth={10}
                />
                <h1 className="is-size-6 has-text-weight-bold">Days Out</h1>
                <p className="p-padding">
                  School holidays with the kids or maybe a catch up with some
                  friends, sit back and get ready for a fun filled day whilst we
                  drive you there.
                </p>
              </div>
              <div className="column is-3 has-text-centered">
                <GiRugbyConversion
                  size={50}
                  color="#16588E"
                  fill="none"
                  strokeWidth={10}
                />
                <h1 className="is-size-6 has-text-weight-bold">
                  Sports Events
                </h1>
                <p className="p-padding">
                  Day at the races, cheer on your favourite football team, or
                  watch the best battle it out at Wimbledon. Whatever your
                  sport, bring the essentials and let us take care of the
                  wheels.
                </p>
              </div>
              <div className="column is-3 has-text-centered">
                <FaUserTie
                  size={50}
                  color="#16588E"
                  fill="none"
                  strokeWidth={10}
                />
                <h1 className="is-size-6 has-text-weight-bold">
                  Corporate Travel
                </h1>
                <p className="p-padding">
                  Need to transport the team to a conference or launch? We have
                  a range of shapes and sizes of vehicle including executive
                  vehicles to get you there on time. We can also offer single or
                  multiple shuttle services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <Quote />
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <div className="columns">
                <div className="column is-6" style={{ color: "grey" }}>
                  <h1 className="title">{mainpitch.title}</h1>
                  <p>{mainpitch.description}</p>
                  <br />
                  <p>{mainpitch.description2}</p>
                  <br />
                  <p>{mainpitch.description3}</p>
                  <br />
                  <p>{mainpitch.description4}</p>
                </div>
                <div className="column is-6">
                  <PreviewCompatibleImage
                    style={{ width: "80%", height: "80%" }}
                    imageInfo={{
                      ...mainpitch,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-info">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-3 has-text-centered">
                <GiSteeringWheel
                  size={50}
                  color="#E7222E"
                  fill="#E7222E"
                  strokeWidth={0.2}
                />
                <h1 className="is-size-6 has-text-weight-bold has-text-black">
                  Professional Drivers
                </h1>
                <p className="p-padding">
                  All our drivers are fully qualified and attend regular
                  training to ensure we maintain the highest of standards. We
                  carry out all relevant background checks to ensure the safety
                  of our customers.
                </p>
              </div>
              <div className="column is-3 has-text-centered">
                <FaShuttleVan
                  size={50}
                  color="#E7222E"
                  fill="none"
                  strokeWidth={30}
                />
                <h1 className="is-size-6 has-text-weight-bold has-text-black">
                  Maintained Vehicles
                </h1>
                <p className="p-padding">
                  Our vehicles never miss a routine check. We maintain our
                  vehicles to the highest standard, cleaning before and after
                  every journey. No shortcuts here!
                </p>
              </div>
              <div className="column is-3 has-text-centered">
                <GiAcousticMegaphone
                  size={50}
                  color="#E7222E"
                  fill="none"
                  strokeWidth={15}
                />
                <h1 className="is-size-6 has-text-weight-bold has-text-black">
                  Great Communication
                </h1>
                <p className="p-padding">
                  We listen to you to ensure no detail of your journey is
                  missed. We are at the end of the phone before, during and
                  after your journey.
                </p>
              </div>
              <div className="column is-3 has-text-centered">
                <HiOutlineCurrencyPound
                  size={50}
                  color="#E7222E"
                  fill="none"
                  strokeWidth={0.1}
                />
                <h1 className="is-size-6 has-text-weight-bold has-text-black">Fair Price</h1>
                <p className="p-padding">
                  We are not about charging the highest price we can. We aim to
                  provide a competitive quote without compromising our great
                  customer service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Features gridItems={intro.blurbs} />

      {/* <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="columns">
            <div className="column is-12">
              <h1 className="tile" style={{ color: "black" }}>
                {heading}
              </h1>
              <p style={{ color: "grey" }}>{description}</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  headline: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        headline={frontmatter.headline}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
          description2
          description3
          description4
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        headline
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxHeight: 200, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
